import React from "react"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import SEO from "../components/seo"

//images
import AdcBackground from "../components/images/AdcBackground"
import ResidentialImage from "../components/images/ResidentialImage"
import CommercialImage from "../components/images/CommercialImage"
import IndustrialImage from "../components/images/IndustrialImage"
//mui items
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"

const styles = theme => ({})

const currentYear = new Date().getFullYear()

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[
        `adc`,
        `American Dream Construction`,
        `Alaska`,
        `General Contractor`,
        `industrial`,
        `commercial`,
        `commercial construction`,
        `renovations`,
        `remodels`,
        `ada`,
      ]}
    />
    <div className="hero-image">
      <div className="wrap">
        <h1 className="hero-text  ">BUILDING THE FUTURE OF ALASKA</h1>
        <h2 className="hero-text"> </h2>
        <AdcBackground />
      </div>
    </div>
    <div className="container-body">
      <div className="container-centered">
        <Typography variant="h5">
          CONSTRUCTION EXCELLENCE SINCE 1997{" "}
        </Typography>
        <Typography variant="paragraph" className="container-margin">
          {" "}
          American Dream Construction, LLC is committed to building quality
          projects while sustaining lifelong partnerships. For { currentYear - 1997} years,
          American Dream has renovated hotels,  built warehouses, residences, and performed tenant improvements and major renovations all over Alaska. Our
          excellent construction results from experienced, trained, and
          credentialed practitioners coming from a wide range of trades to
          ensure that the job gets done right the first time.
        </Typography>
        <Grid container spacing={32} style={{ marginBottom: "30px" }}>
          <Grid item xs={12} sm={4}>
            <Link to="/residential" className="links">
              <div className="clip-circle">
                <ResidentialImage />
              </div>

              <Typography variant="h5"> Residential</Typography>
            </Link>
            <Typography variant="paragraph">
              {" "}
              ADC's exceptional personal service has earned the trust of
              discerning homeowners.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link to="/commercial" className="links">
              <div className="clip-circle">
                <CommercialImage />
              </div>
              <Typography variant="h5"> Commercial </Typography>
            </Link>
            <Typography variant="paragraph">
              {" "}
              ADC has the experience and expertise to get the job done on time
              and on budget.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Link to="/industrial" className="links">
              <div className="clip-circle">
                {" "}
                <IndustrialImage />
              </div>
              <Typography variant="h5"> Industrial </Typography>
            </Link>
            <Typography variant="paragraph">
              {" "}
              ADC tackles challenging projects with a proven track record of
              creative problem solving.
            </Typography>
          </Grid>
        </Grid>
      </div>
      <div className="container-centered">
        {" "}
        <Button
          size="large"
          variant="contained"
          color="primary"
          component={Link}
          to="/services"
          className="links-body "
        >
          View Our Services
        </Button>
      </div>
    </div>
  </Layout>
)

export default withStyles(styles, { withTheme: true })(IndexPage)
